import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useLanguage from '../../Hooks/useLanguage';
import ButtonLink from '../../UI/ButtonLink';

const TrainingsPage = ({ trainingsData }) => {

  const langToggle = useLanguage;
  return (
    <div className="trainings-page">
      {trainingsData.map((item, index) => {
        const image = getImage(item.image.localFile);

        return (
          <section className="trainings-item" key={index}>
            <div className="trainings-item__image">
              <GatsbyImage
                className="training-image"
                image={image}
                alt={langToggle(item.title_ua, item.title_ru, item.title_en)}
              />
            </div>
            <div className="trainings-item__content">
              <span className="training-date__wrapper">
                <span className="training-date">{item.Data}</span>
              </span>
              <h3 className="training-title">
                {langToggle(item.title_ua, item.title_ru, item.title_en)}
              </h3>
              <p className="training-text">
                {langToggle(
                  item.about_description_ua,
                  item.about_description_ru,
                  item.about_description_en
                )}
              </p>
              <ButtonLink
                to={'/trainings/' + item.slug}
                title={langToggle(
                  'Дізнатись більше',
                  'Узнать больше',
                  'Read More'
                )}
              />
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default TrainingsPage;
